<template>
	<div style="padding-bottom:20px;">
		<mobile-header active="/mobile/companyActive"></mobile-header>
		<h4 style="margin-bottom: 0;" class="font2">招投审计</h4>
		<p class="borderBottom"><span>&nbsp;</span></p>
		<div style="padding:0 20px;">
			<img src="@assets/images/companyActive/公司业务—审计.jpg" style="width: 100%;" />
		</div>
		<div style="padding:0 10px;color: #595959;font-size: 14px;line-height: 25px;">
			<p style="text-indent: 2em;">
				招投研究院具有专业的招标审计团队，通过对招投标制度和流程、招投标实施过程、招标方案及相关文件进行审查，从合法性、规范性层面提出整改建议。
			</p>
		</div>
		
		<div style="text-align: center;margin-top: 40px;">
			<h4 style="margin-bottom: 0;" class="font2">全过程审计</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="padding:0 10px;">
				<img src="@assets/images/tenderTotal/1.jpg" style="width: 100%;" />
			</div>
			<p class="clumn">
				<span class="blue">事前</span>
				<span>规避招标</span>
				<span>虚假招标</span>
				<span>条款违约</span>
			</p>
			<p class="clumn">
				<span class="blue">事中</span>
				<span>程序违法</span>
				<span>评分不当</span>
				<span>围标串标</span>
			</p>
			<p class="clumn">
				<span class="blue">事后</span>
				<span>违法变更</span>
				<span>违规结算</span>
				<span>资料不清</span>
			</p>
		</div>
	

		<div style="text-align: center;margin:40px 10px 0 10px;" id="good">
			<h4 style="margin-bottom: 0;" class="font2">服务优势</h4>
			<p class="borderBottom"><span>&nbsp;</span></p>
			<div style="display: flex;">
				<div style="flex:1;padding: 0%;">
					<p>资质齐全</p>
				</div>
				<div style="flex:1;padding: 0% 5px;">
					<p>实力团队</p>
				</div>
				<div style="flex:1;padding: 0%;">
					<p>业绩众多</p>
				</div>
			</div>
			<div style="display: flex;">
				<div style="flex:1;padding: 0%;">
					<p>经验丰富</p>
				</div>
				<div style="flex:1;padding: 0% 5px;">
					<p>技术支撑</p>
				</div>
				<div style="flex:1;padding: 0%;">
					<p>涵盖面广</p>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped>
	.image {
		width: 100%;
	}

	.font2{
		background-image: -webkit-gradient( linear, left top, right top, color-stop(0.3, #22f), color-stop(0.6, #2ff) );
		color: transparent;
		-webkit-background-clip: text;
		text-align: center;
	}
	.borderBottom{
		text-align: center;
		margin:0 0 10px 0;
	}
	.borderBottom span{
		border-bottom:#FF8700 3px solid ;
		display: inline-block;
		width: 20px;
	}
	.clumn {
		margin-bottom:25px;
		text-align: left;
		margin:5px 10px;
		padding:0 5px 0 0;
		background-color: #EBEEF5;
		height:30px;
		line-height:30px;
	}

	.clumn .blue {
		background-color: #409EFF;
		color: #fff;
		height:30px;
		display: inline-block;
	}

	.clumn span {
		padding: 0 10px;
		font-size: 14px;
	}

	#good div p {
		background-color: #409EFF;
		color: #fff;
		font-size: 14px;
		font-weight: 500;
		padding:10px 0;
		margin:5px 0;
	}

	.sameWidth {
		width: 1300px;
		position: relative;
		left: 50%;
		margin-left: -650px !important;
		box-sizing: border-box;
	}
</style>


<script>
	import mobileHeader from "@components/mobileHeader";
	export default {
		name:'tenderTotal',
		metaInfo: {
			title: '招标审计',
			meta: [{
					name: 'keyWords',
					content: '招标审计,规避招标,虚假招标,围标串标,招标方案,招投标制度'
				},
				{
					name: 'description',
					content: '招投研究院具有专业的招标审计团队，通过对招投标制度和流程、招投标实施过程、招标方案及相关文件进行审查，从合法性、规范性层面提出整改建议。'
				}
			]
		},
		components: {
			mobileHeader,
		},
		data() {
			return {
				headersrc: require("@assets/image/公司前台.jpg"),
				youshi: [{
						id: 1,
						src: require("@assets/images/tenderAgent/经验丰富.jpg"),
						title: "业务经验丰富",
						desc: '　　核心团队具有十余年招标代理、政府采购代理、招标规范化审计经验，累计完成代理额超过100亿元。',
					},
					{
						id: 2,
						src: require("@assets/images/tenderAgent/专业团队.jpg"),
						title: "专业团队",
						desc: '　　招投研究院80%以上为本科以上学历或中高级以上职称；聘请了原广东省财政厅政府采购监管处退休专家、,华南理工大学工商管理学院教授、广东正大方略律师事务所高级合伙人等一批专家团队，强大的专家团队确保项目顺利履行。',
					},
					{
						id: 3,
						src: require("@assets/images/tenderAgent/良好口碑.jpg"),
						title: "良好口碑",
						desc: '　　近年来服务广东、湖南、湖北、山东、广西、云南等地区的 300 多家企事业单位，取得了良好的社会声誉。目前主要行业包括：,（1）电力电网及其供应商；（2）运营商及其供应商；（3）政府采购各行各业优秀供应商。',
					}
				],
			};
		},
		mounted: function() {
			this.bus.$emit('headerImg', this.headerImgMobile);
		}
	};
</script>
